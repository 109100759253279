import _typeof from "/Users/beixinyuan/Documents/www/newParty1/party-pc/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.number.constructor.js";
import { defineComponent, onMounted, reactive } from "vue";
import $store from "@/store/index";
import xss from "xss";
export default defineComponent({
  name: "QuestionCardTips",
  props: {
    detail: {
      type: Object,
      default: null
    },
    istype: {
      type: Number,
      default: null
    },
    alphabet: {
      type: Array,
      default: null
    }
  },
  setup: function setup(props) {
    onMounted(function () {
      Data.intro = props.detail.intro;
    });
    var Data = reactive({
      intro: ""
    }); // 防xss注入

    var getXssIntro = function getXssIntro() {
      if ($store.state && _typeof($store.state) == "object") {
        if (Data.intro && Data.intro != "null") {
          return xss(Data.intro || "无", $store.state["xssOptions"]);
        } else {
          return "无";
        }
      }
    };

    return {
      Data: Data,
      getXssIntro: getXssIntro
    };
  }
});