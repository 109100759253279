import "core-js/modules/es.number.constructor.js";
import "swiper/swiper.scss";
import { defineComponent, onMounted } from "vue";
import NingTips from "@/components/QuestionCard/tips.vue"; // import NingTitle from "@/components/QuestionCard/title.vue"

export default defineComponent({
  name: "QuestionCard",
  components: {
    NingTips: NingTips
  },
  props: {
    options: {
      type: Array,
      default: null
    },
    reply: {
      type: String,
      default: null
    },
    answer: {
      type: String,
      default: null
    },
    //ABCD
    alphabet: {
      type: Array,
      default: null
    },
    type: {
      type: Number,
      default: null
    }
  },
  setup: function setup() {
    onMounted(function () {// console.log(props);
    });
    return {};
  }
});